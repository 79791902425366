<template>
  <CContainer class="text-black">
    <div class="row">
      <div class="col text-left">
        <a @click="goback()">
          <CIcon name="cil-chevron-left" size="lg"></CIcon>
        </a>
      </div>
      <div class="col text-center mt-1">
        <h6 class="font-weight-bold">รายการสั่งซื้อ</h6>
      </div>
      <div class="col text-right">
        <router-link to="/menu" class="text-black">
          <span style="padding: 10px">
            <CIcon name="cil-x" size="lg"></CIcon>
          </span>
        </router-link>
      </div>
    </div>
    <hr />
    <div class="d-flex justify-content-between mt-4">
      <h5 class="mt-0 font-weight-bold">
        <CIcon class="mb-1" name="cil-basket"></CIcon>
        รายการสั่งซื้อ
      </h5>
      <router-link to="/menu">
        <small class="text-description"> {{ cartTotal }} รายการ </small>
      </router-link>
    </div>
    <hr style="margin-top: 5px" />
    <table style="width: 100%" aria-describedby="">
      <th scope="col"></th>
      <tr
        v-for="list in cartLists"
        :key="list.productPLUId"
        style="border-bottom: 1px solid #e5e5e5"
      >
        <td style="width: 30%; padding: 5px">
          <div
            v-if="
              list.remoteImagePath === null ||
              list.remoteImagePath === undefined
            "
            class="square-box"
            :style="{
              'background-color': getImgColor(list.indexColor),
              width: '100%',
            }"
          ></div>
          <img
            v-else
            alt=""
            :src="list.remoteImagePath"
            class="img-fluid"
            style="border-radius: 5px"
            onerror="this.onerror=null;this.src='https://www.img.in.th/images/d6fa08b8f195f3808f7f3bbcb8e43c9f.png';"
          />
        </td>
        <td style="padding: 10px">
          <div class="d-flex justify-content-between">
            <h6 class="text-black">
              {{ list.productPLU.name }}
              <br />
              <small class="text-description">
                {{ list.note }}
              </small>
            </h6>

            <h6 class="font-weight-bold">฿{{ summary(list.totalAmount) }}</h6>
          </div>
          <div class="d-flex justify-content-between">
            <small class="text-description"> จำนวน: {{ list.quantity }} </small>
          </div>
        </td>
      </tr>
    </table>

    <div class="d-flex justify-content-between mt-4">
      <h5 class="mb-0 font-weight-bold">
        <CIcon class="mb-1" name="cil-truck"></CIcon> การจัดส่ง
      </h5>
    </div>
    <hr style="margin-top: 5px" />
    <ul class="list-group">
      <li class="list-group-item">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            value="true"
            v-model="delivery"
          />
          <label class="form-check-label" style="font-size: 12px">
            <strong> รับเองที่ร้าน </strong>
          </label>
        </div>
      </li>
      <li class="list-group-item">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            value="false"
            v-model="delivery"
          />

          <label
            class="form-check-label d-flex justify-content-between mb-2"
            style="font-size: 12px"
          >
            <strong class="mt-1"> จัดส่งตามที่อยู่ </strong>
            <a data-toggle="modal" data-target="#addressModal">
              <small style="font-size: 10px" class="text-description">
                เปลี่ยนที่อยู่
              </small>
            </a>
          </label>
        </div>

        <small>
          <strong class="text-grey">
            {{ name }}
          </strong>
          <br />
          {{ address }}
        </small>
      </li>
    </ul>

    <router-link to="/menu/address" replace>
      <button class="mt-3 btn btn-block btn-outline-greyborder text-grey">
        <strong style="font-size: 12px"> + เพิ่มที่อยู่ </strong>
      </button>
    </router-link>

    <hr style="border: 1px solid grey" />

    <div class="d-flex justify-content-between">
      <h6>รวม</h6>
      <h6>฿{{ totalCost }}</h6>
    </div>
    <div class="d-flex justify-content-between">
      <h6>ค่าจัดส่ง</h6>
      <h6>฿0.00</h6>
    </div>
    <br />
    <div class="d-flex justify-content-between mt-3">
      <h5 class="font-weight-bold">รวมทั้งสิ้น</h5>
      <h5 class="font-weight-bold">฿{{ totalCost }}</h5>
    </div>

    <button class="mt-3 btn btn-block btn-success mb-5" @click="goToPayment()">
      <strong style="font-size: 12px"> ชำระเงิน </strong>
    </button>

    <div class="modal fade modal-p-bottom" id="addressModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body py-3">
            <address-lists
              :address="address"
              @update-address="addAddress"
            ></address-lists>
          </div>
        </div>
      </div>
    </div>
  </CContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import AddressLists from './AddressLists'
import util from '@/util/util'
import order from '@/util/order'
import pos from '@/services/pos'
import '@/util/menu.css'

export default {
  components: {
    AddressLists,
  },
  data() {
    return {
      loadingButton: true,
      cartLists: [],
      delivery: true,
      name: 'นาตาชา โรมานอฟ',
      address:
        '1768 Thai Summit Tower, 16 Floor, New Phetchaburi Road, Bang Kapi,Bangkok 10310',
    }
  },
  computed: {
    ...mapGetters(['shopLineOA', 'shopLineObjectId']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shop() {
      return this.shopLineOA.find(
        (detail) => detail.objectId === this.shopLineObjectId
      )
    },
    shopName() {
      if (this.shopLineOA.length > 0) {
        return this.shopLineOA[0].shopName
      } else {
        return ''
      }
    },
    logo() {
      if (this.shopLineOA.length > 0) {
        return this.shopLineOA[0].remoteImagePath
      } else {
        return ''
      }
    },
    totalCost() {
      let total = this.cartLists.reduce((accum, product) => {
        return accum + product.totalAmount
      }, 0)

      sessionStorage.setItem('amount', total)
      return util.convertCurrency(total)
    },
    cartTotal() {
      let cart = JSON.parse(sessionStorage.getItem('cart'))
      if (cart) {
        return cart.length
      } else {
        return 0
      }
    },
  },
  created() {
    this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
  },
  methods: {
    ...util,
    getImgColor(indexColor) {
      return util.generateColor(indexColor)
    },
    addAddress(ads) {
      this.address = ads
    },
    goback() {
      window.history.back()
    },
    summary(amount) {
      return util.convertCurrency(amount)
    },
    goToPayment() {
      this.$router.push('/payment')
    },
    placeOrder() {
      this.loadingButton = false
      let data = order.orderDefault(this.shop, this.cartLists)

      this.$router.push('/payment')

      console.log(data)

      this.loadingButton = true
      sessionStorage.removeItem('cart')

      /* pos({
        url: '/api/v1.0/' + this.uid + '/Receipt/createorder',
        data: data,
        method: 'post',
      }).then((res) => {
        console.log(res)
        this.loadingButton = true
        this.successShow = true
        localStorage.removeItem('cart')
      }) */
    },
  },
}
</script>
