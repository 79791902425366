<template>
  <div>
    <p class="text-center">
      <strong> ที่อยู่ </strong>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span style="float:right"><CIcon name="cil-x"></CIcon></span>
      </button>
    </p>

    <table style="width:100%;" aria-describedby="">
      <th scope="col"></th>
      <tr
        data-dismiss="modal"
        v-for="(category, index) in 4"
        :key="category.objectId"
        :value="category.objectId"
        @click="selectAddress(index)"
        style="border-bottom:1px solid #E5E5E5"
      >
        <td style="padding:5px">
          <h6 class="mt-1" style="font-size:12px;">
            <CIcon class="mb-1" name="cil-map"></CIcon>
            <strong> ที่ทำงาน {{ index + 1 }} </strong>
          </h6>

          <small class="text-description">
            {{ index + 1 }}768 Thai Summit Tower, 16 Floor, New Phetchaburi
            Road, Bang Kapi,Bangkok 10310
          </small>
        </td>
      </tr>
    </table>

    <router-link  to="/smart-menu/address" replace>
      <button class="mt-3 btn btn-block btn-outline-greyborder text-grey">
        <strong style="font-size:12px"> + เพิ่มที่อยู่ใหม่ </strong>
      </button>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import pos from '@/services/pos'
import util from '@/util/util'

export default {
  props: ['address'],
  data() {
    return {
      products: [],
      categories: [],
      branchName: '',
      totally: 0,
    }
  },
  computed: {
    ...mapGetters(['shopObjectId']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
  },
  created() {
    this.getCategory()
  },
  methods: {
    ...util,
    goToAddNewAddress() {
      this.$router.push('/smart-menu/address')
    },
    selectAddress(index) {
      let t = index + 1
      let newAddress =
        t +
        '768 Thai Summit Tower, 16 Floor, New Phetchaburi Road, Bang Kapi,Bangkok 10310'
      this.$emit('update-address', newAddress)
    },
    getCategory() {
      const uid = this.uid
      let shopObjectId = this.shopObjectId

      pos
        .get('/api/v1.0/shop/' + uid + '/Category/menu/' + shopObjectId)
        .then((res) => {
          this.categories = res.data.data
        })
    },
  },
}
</script>
